<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>视听威有</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div>
                <VyAddBtn @click.native="openAdd" />
                <!-- <button @click="openAdd">添加</button> -->
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="icon" align="center" label="图标">
                    <template slot-scope="{ row }">
                        <img :src="row.icon" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="url" align="center" label="链接"> </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#7781f1' : '#ff4949' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" align="center" label="标签">
                    <template slot-scope="{ row }">
                        <span>{{ row.tag == 1 ? "置顶" : "推荐" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="_operate">
                            <button @click="edit(scope.row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(scope.row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹出框 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogVisible"
            width="40%"
            custom-class="edit"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <uploadImg :src="form.image" @removeSrc="form.image = ''" ref="image"></uploadImg>
                </el-form-item>
                <el-form-item label="图标">
                    <uploadImg :src="form.icon" @removeSrc="form.icon = ''" ref="icon"></uploadImg>
                </el-form-item>
                <el-form-item label="链接">
                    <el-input v-model="form.url"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="开启"
                        inactive-text="关闭"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="标签">
                    <el-select v-model="form.tag" placeholder="选择类型">
                        <el-option label="置顶" :value="1"></el-option>
                        <el-option label="推荐" :value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="yes">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import uploadImg from "@/components/customs/uploadImg.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { uploadImg, VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {},
            dialogVisible: false,
        };
    },
    async created() {
        this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/website/llvy_list");
            this.tableData = data;
        },
        openAdd() {
            this.form = {
                title: "",
                image: "",
                icon: "",
                url: "",
                status: 1,
                tag: 1,
            };
            this.dialogVisible = true;
        },
        // 打开编辑框
        edit(row) {
            this.form = {
                ...row,
            };
            this.dialogVisible = true;
        },
        // 确认编辑
        async yes() {
            let { form } = this;
            form.image = this.$refs.image.url;
            form.icon = this.$refs.icon.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/website/llvy_up", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/website/llvy_add", qs.stringify(form));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                await this.getData();
                this.$message.success(data.msg);
            }
            this.dialogVisible = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/website/llvy_del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                await this.getData();
                this.$message.success(data.msg);
            }
            this.dialogVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    /deep/.edit {
        border-radius: 20px;
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
